import axios from 'axios'

const APP_API_DOMAIN = process.env.appApiDomain
const APP_URL_NEWSLETTER = process.env.appNewsletter
const APP_URL_FORMDESK = process.env.appFormdesk

const APIS = {
  ElensApi: axios.create({
    maxRedirects: 0,
    baseURL: APP_API_DOMAIN
  }),
  newsletter: axios.create({
    baseURL: APP_URL_NEWSLETTER
  }),
  formdesk: axios.create({
    baseURL: APP_URL_FORMDESK,
    headers: {
      Authorization: 'Bearer OCTADESK.eyJuYmYiOjE2MzcwNjgwNDAsImV4cCI6MTk1ODQ3NjA0MCwiaXNzIjoiaHR0cDovL2lkZW50aXR5LXNlcnZlci5vY3RhZGVzay5zZXJ2aWNlcyIsImF1ZCI6WyJodHRwOi8vaWRlbnRpdHktc2VydmVyLm9jdGFkZXNrLnNlcnZpY2VzL3Jlc291cmNlcyIsImdlbmVyYWwiXSwiY2xpZW50X2lkIjoicHMxWHNDejByd1Z1Iiwic3ViIjoiNGJmMTkwNWItNzM2OC00MGMzLTg3NGMtNzA4YTIwNTg1ODUyIiwiYXV0aF90aW1lIjoxNjM3MDY4MDQwLCJpZHAiOiJsb2NhbCIsInN1YmRvbWFpbiI6ImUzb3B0aWNhbCIsInJvbGUiOiJvd25lciIsInR5cGUiOiJub25lIiwic2NvcGUiOlsiZ2VuZXJhbCJdLCJhbXIiOlsiQmVhcmVyIl19.MxNDdQ4dKeQIxeOPf7J70nXEjCpRixB2WFk7kMatzo6GS9TGRMV0xGM9Uef3dM0IlDpMUXz-IXadO2Cdr9bA4zloKKrB6RsSwYJ8VtjP_z9s8QGHa9ns7FIT7E76dPOAaUuaox9ntW7loQziZTalrPR0P_V-G3-3e2-FNKhvQLd4g84mdNXVf0kD_nIM7FOlIx032UCGpE4T8nymoUIKq8wx3jCZ1V2ss5VmbnFzZPv79nXcoMK19FcZpqwC6nxwxR8xI22NbYORL4kZVgYDJSIvtk5JGK0LIhHUZZV8yTD6vEBWBnblHA0W8JZx0EmYAkwDEk_UEJksMt7aDMJ2fA'
    }
  })
}

export default APIS
