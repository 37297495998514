
import axios from '@/plugins/axios'
export default {
  name: 'VFooterForm',
  components: {
    IconAdd: () => import('@/assets/icons/icon_addimage.svg')
  },
  data: () => ({
    showLoading: false,
    informe: false,
    RecipeLensesUpload: null,
    GuaranteeUpload: null,
    contact: {
      name: '',
      email: '',
      phone: '',
      subject: '',
      number: '',
      message: '',
      productmsg: ''
    },
    recipe: {
      lenses: {
        check: true,
        preview: null,
        error: false,
        brands: ''
      }
    },
    photoProduct: null,
    message: {
      status: false,
      type: '',
      content: ''
    }
  }),
  methods: {
    async uploadRecipeLenses(event) {
      try {
        const data = new FormData()
        const file = event.target.files[0]
        this.recipe.lenses.preview = file.name
        data.append('file', file)
        const config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        await axios.formdesk.post('/tickets/attachments/upload', data, config).then((response) => {
          this.RecipeLensesUpload = response.data.url
          this.recipe.lenses.error = false
        })
      } catch (error) {
        this.RecipeLensesUpload = null
      }
    },
    removeRecipeLenses() {
      this.recipe.lenses.preview = null
      this.recipe.lenses.error = false
      this.RecipeLensesUpload = null
      this.file = null
    },
    selectPhotoProduct() {
      const image3 = this.$refs.file3.files[0]
      const reader3 = new FileReader()
      reader3.readAsDataURL(image3)
      reader3.onload = (e) => {
        this.photoProduct = e.target.result
      }
    },
    async uploadGuarantee(event) {
      try {
        this.selectPhotoProduct()
        const data = new FormData()
        const file = event.target.files[0]
        data.append('file', file)
        const config = {
          header: {
            'Content-Type': 'multipart/form-data'
          }
        }
        await axios.formdesk.post('/tickets/attachments/upload', data, config).then((response) => {
          this.GuaranteeUpload = response.data.url
        })
      } catch (error) {
        this.GuaranteeUpload = null
      }
    },
    removePhotoProduct() {
      this.photoProduct = null
      this.GuaranteeUpload = null
      this.file = null
    },
    async sendForm() {
      const isValid = await this.$validator.validateAll()
      if (this.contact.subject === 'Receita') {
        if (this.recipe.lenses.check && this.recipe.lenses.preview === null) {
          this.recipe.lenses.error = true
        } else {
          this.recipe.lenses.error = false
        }
      }
      if (isValid && !this.recipe.lenses.error) {
        this.message.status = true
        this.message.type = 'waint'
        this.message.content = 'Aguarde...'
        try {
          let jsonFormDesk = ''
          if (this.contact.subject === 'Receita') {
            // Receita Lentes
            if (this.recipe.lenses.check) {
              jsonFormDesk = {
                requester: {
                  email: `${this.contact.email}`,
                  name: `${this.contact.name}`
                },
                summary: `${this.contact.subject} - ${this.contact.number || 'Contato Site'}`,
                customField: {
                  categoria: `${this.contact.subject}`,
                  site: 'E-lens',
                  motivo: 'Receita Lente de Contato',
                  numero_pedido: `${this.contact.number}`
                },
                comments: {
                  description: {
                    content: `Telefone: ${this.contact.phone || '---'} \n Mensagem: ${this.contact.message} \n Preferência da marca: ${this.recipe.lenses.brands || '-'}`,
                    attachments: [
                      {
                        url: `${this.RecipeLensesUpload}`
                      }
                    ]
                  }
                }
              }
            }
          } else if (this.contact.subject === 'Garantia') {
            jsonFormDesk = {
              requester: {
                email: `${this.contact.email}`,
                name: `${this.contact.name}`
              },
              summary: `${this.contact.subject} - ${this.contact.number || 'Contato Site'}`,
              customField: {
                categoria: `${this.contact.subject}`,
                site: 'E-lens',
                numero_pedido: `${this.contact.number}`
              },
              comments: {
                description: {
                  content: `Telefone: ${this.contact.phone || '---'} \n O que aconteceu com o produto: ${this.contact.productmsg} \n Mensagem: ${this.contact.message}`,
                  attachments: [
                    {
                      url: `${this.GuaranteeUpload}`
                    }
                  ]
                }
              }
            }
          } else {
            jsonFormDesk = {
              requester: {
                email: `${this.contact.email}`,
                name: `${this.contact.name}`
              },
              summary: `${this.contact.subject} - ${this.contact.number || 'Contato Site'}`,
              customField: {
                categoria: `${this.contact.subject}`,
                site: 'E-lens',
                numero_pedido: `${this.contact.number || 0}`
              },
              comments: {
                description: {
                  content: `Telefone: ${this.contact.phone || '---'} \n Mensagem: ${this.contact.message}`
                }
              }
            }
          }
          await axios.formdesk.post('/tickets', jsonFormDesk).then((response) => {
            this.message.type = 'success'
            this.message.content = 'Contato enviado com sucesso!'
          })
          const emptyFormContact = Object.assign({}, this.emptyFormAll())
          this.contact = Object.assign(emptyFormContact)
          const emptyFormRecipe = Object.assign({}, this.emptyFormRecipe())
          this.recipe = Object.assign(emptyFormRecipe)
          this.$validator.pause()
          this.$nextTick(() => {
            this.$validator.errors.clear()
            this.$validator.fields.items.forEach(field => field.reset())
            this.$validator.fields.items.forEach(field => this.errors.remove(field))
            this.$validator.resume()
          })
          this.removeRecipeLenses()
          this.removeRecipeGlasses()
          setTimeout(() => {
            this.message.status = false
            this.message.type = ''
            this.message.content = ''
          }, 5000)
        } catch (error) {
          // this.message.type = 'error'
          // this.message.content = 'Não foi possível enviar o contato, tente novamente.'
        }
        setTimeout(() => {
          this.message.status = false
          this.message.type = ''
          this.message.content = ''
        }, 5000)
      }
    },
    emptyFormAll() {
      return {
        name: '',
        email: '',
        phone: '',
        subject: '',
        number: '',
        message: '',
        productmsg: ''
      }
    },
    emptyFormRecipe() {
      return {
        lenses: {
          check: true,
          preview: null,
          error: false,
          brands: ''
        }
      }
    }
  }
}
